<template>
    <div class="o-ezekia-nav">
        <div class="o-ezekia-nav__inner">
            <div class="o-ezekia-nav__left">
                <div
                    class="o-ezekia-nav__logo"
                    style="background-image: url('/images/ezekia/Ezekia-logo-final2-inversed.png')"
                >
                </div>
            </div>
            <form
                ref="logoutForm"
                id="logout-form"
                action="/logout"
                method="POST"
                style="display: none;"
            >
                <input
                    type="hidden"
                    name="_token"
                    :value="token"
                >
            </form>

            <div class="o-ezekia-nav__right o-ezekia-nav__large">
                <div class="o-ezekia-nav__links">
                    <div
                        class="o-ezekia-nav__link u-button-cta u-hover--grow"
                        @click="enter"
                    >
                        {{ loggedIn ? trans('home-ezekia.nav.enter') : trans('home-ezekia.nav.signup') }}
                    </div>
                    <router-link
                        class="o-ezekia-nav__link o-ezekia-nav__link--text
                         o-ezekia-nav__link--button u-button-cta"
                        :to="loggedIn ? '/logout' : loginUrl "
                    >
                        {{ loggedIn ? trans('home-ezekia.nav.logout') : trans('home-ezekia.nav.login') }}
                    </router-link>
                </div>
            </div>

            <div
                v-blur="closeRespDropdown"
                class="o-ezekia-nav__resp o-ezekia-nav__small"
            >

                <div
                    class="o-ezekia-nav__burger u-hover--grow"
                    @click="respDropdown = !respDropdown"
                >
                    <i class="fal fa-bars"></i>
                </div>

                <div
                    v-show="respDropdown"
                    class="o-ezekia-nav__resp-dropdown"
                >
                    <div v-show="!respContent">
                        <a
                            class="o-ezekia-nav__resp-line o-ezekia-nav__resp-main"
                            @click="enter"
                        >
                            {{ loggedIn ? trans('home-ezekia.nav.enter') : trans('home-ezekia.nav.signup') }}
                        </a>
                        <router-link
                            class="o-ezekia-nav__resp-line o-ezekia-nav__resp-main"
                            :to="loggedIn ? '/logout' : loginUrl "
                            @click="closeRespDropdown"
                        >
                            {{ loggedIn ? trans('home-ezekia.nav.logout') : trans('home-ezekia.nav.login') }}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { researcherStore } from './../../researcher/researcherStore.js';

export default {
    name: 'EzekiaNav',
    data() {
        return {
            respDropdown: false,
            respContent: false,
            loginUrl: { name: 'login' },
            signupUrl: { name: 'register' },
        };
    },
    computed: {
        loggedIn() {
            return researcherStore.loggedIn();
        },
    },
    methods: {
        closeRespDropdown() {
            this.respDropdown = false;
        },
        enter() {
            if (window.awaitingMandatory2FA) {
                return this.$router.push({ path: '/login/mandatory-2fa' });
            } else if (window.awaiting2FA) {
                return this.$router.push({ path: '/login/token' });
            } else if (window.browserNeedsVerification) {
                return this.$router.push({ path: '/login/new-browser-pin' });
            }

            if (!researcherStore.finishedRegistration()) {
                return this.$router.push(this.signupUrl).catch(_.noop);
            }

            window.location.href = '/';
        },
    },
    watch: {
        respDropdown(newValue) {
            if (!newValue) {
                this.respContent = false;
            }
        },
    },
    created() {
        this.token = window.token;
    },
};
</script>

<style lang="scss" scoped>

@import "@sass/mixins";
@import "@sass/variables";
@import "@sass/home-ezekia/variables";
@import "@sass/home-ezekia/utilities";

.o-ezekia-nav {
    background-color: $color__navy-400;
    color: #fff;
    font-size: 14px;
    width: 100%;

    &__inner {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 3px 30px;
    }

    &__left {
        align-items: center;
        display: flex;
        padding: 5px 0;
    }

    &__logo {
        background: no-repeat center center;
        background-size: contain;
        height: 50px;
        width: 160px;
    }

    &__right {
        align-items: center;
        display: flex;
    }

    &__links {
        display: flex;
        margin-right: 10px;
    }

    &__link {
        align-self: center;
        background-color: $color__yellow-400;
        border: 2px solid $color__yellow-400;
        color: #000;
        font-weight: 600;
        padding: 10px 24px;
        position: relative;

        &--text {
            color: white;
            font-size: 14px;

            &:hover {
                color: $color__cobalt-300;
            }
        }

        &--button {
            background-color: $color__navy-400;

            &:hover {
                background-color: #FFF;
            }
        }
    }

    &__small {
        display: none;
    }

    &__burger {
        font-size: 30px;
        line-height: 18px;
        padding: 14px 0;
    }

    &__resp {
        position: relative;

        &-dropdown {
            background-color: $color__navy-400;
            box-shadow: 0 4px 8px -1px rgb(0 0 0 / 20%);
            position: absolute;
            right: -20px;
            top: 47px;
            width: 220px;
            z-index: z("over");
        }

        &-main {
            border-left: 2px solid transparent;
            justify-content: space-between;
            padding: 18px 20px 14px;
        }

        &-line {
            align-items: center;
            border-left: 2px solid transparent;
            display: flex;

            &:hover {
                background-color: $color__cobalt-100;
                color: $color__cobalt-400;
            }
        }
    }

    @media (width <= 1040px) {
        &__left {
            padding: 0;
        }
    }

    @media (width <= 550px) {
        &__large {
            display: none;
        }

        &__small {
            display: block;
        }

        &__resp-main {
            padding: 12px 20px;
        }
    }

    @media (width <= 350px) {
        &__resp-main {
            padding: 10px 20px;
        }

    }

}

.active {
    color: $color__cobalt-300;
    font-weight: 600;
}

.u-button-cta {
    align-self: center;
    border-radius: 20px;
    margin: 0 0 0 25px;
    padding: 3px 25px;
}

</style>