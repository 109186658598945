<template>
    <div class="c-ezekia-footer">
        <div class="c-ezekia-footer__inner b-nal-layout__inner">
            <div class="c-ezekia-footer__main">
                <div class="c-ezekia-footer__sides">
                    <router-link
                        to="/home"
                        exact
                    >
                        <div
                            class="c-ezekia-footer__logo"
                            style="background-image: url('/images/ezekia/Ezekia-logo-final2-inversed.png')"
                        >
                        </div>
                    </router-link>
                </div>
                <div class="c-ezekia-footer__links">
                    <p class="c-ezekia-footer__header">
                        Quick Links
                    </p>
                    <div class="c-ezekia-footer__content">
                        <a
                            v-for="(link,index) in footerLinks"
                            :key="index"
                            class="c-ezekia-footer__link u-hover--line"
                            :href="link.url"
                            target="_blank"
                        >
                            {{ link.title }}
                        </a>
                    </div>
                </div>
                <div class="c-ezekia-footer__address">
                    <p class="c-ezekia-footer__header">
                        Ezekia
                    </p>
                    <div class="c-ezekia-footer__content">
                        <p class="c-ezekia-footer__company">
                            {{ trans('common.company-info', {}, 'en') }}
                        </p>
                        <p>{{ trans('common.address', {}, 'en') }}</p>
                    </div>
                </div>
                <div class="c-ezekia-footer__sides">
                    <qms-certificate></qms-certificate>
                </div>
            </div>
            <div class="c-ezekia-footer__footer">
                <div class="c-ezekia-footer__copyright">
                    &copy;
                    {{ year }}
                    &nbsp;
                    Ezekia
                </div>
                <!--                  Initially introducing with only EN by default-->
                <!--              Commented out for now-->
                <!--                <div class="c-ezekia-footer__language">-->
                <!--                    <a-->
                <!--                        class="u-hover&#45;&#45;line"-->
                <!--                        :href="localeLink('en')"-->
                <!--                    >-->
                <!--                        EN-->
                <!--                    </a>-->
                <!--                    |-->
                <!--                    <a-->
                <!--                        class="u-hover&#45;&#45;line"-->
                <!--                        :href="localeLink('de')"-->
                <!--                    >-->
                <!--                        DE-->
                <!--                    </a>-->
                <!--                    |-->
                <!--                    <a-->
                <!--                        class="u-hover&#45;&#45;line"-->
                <!--                        :href="localeLink('es')"-->
                <!--                    >-->
                <!--                        ES-->
                <!--                    </a>-->
                <!--                    |-->
                <!--                    <a-->
                <!--                        class="u-hover&#45;&#45;line"-->
                <!--                        :href="localeLink('fr')"-->
                <!--                    >-->
                <!--                        FR-->
                <!--                    </a>-->
                <!--                    |-->
                <!--                    <a-->
                <!--                        class="u-hover&#45;&#45;line"-->
                <!--                        :href="localeLink('pt-BR')"-->
                <!--                    >-->
                <!--                        PT-BR-->
                <!--                    </a>-->
                <!--                </div>-->
                <!--                <div class="c-ezekia-footer__icons">-->
                <!--                    <a-->
                <!--                        class="fa-2xl fa-brands fa-linkedin-in c-ezekia-footer__icon u-hover&#45;&#45;grow"-->
                <!--                        href="https://www.linkedin.com/company/ezekia/"-->
                <!--                        target="_blank"-->
                <!--                    >-->
                <!--                    </a>-->
                <!--                    <a-->
                <!--                        class="fa-2xl fa-brands fa-x-twitter c-ezekia-footer__icon u-hover&#45;&#45;grow"-->
                <!--                        href="https://twitter.com/intent/follow?screen_name=EzekiaCRM&original_referer=https://ezekia.com/"-->
                <!--                        target="_blank"-->
                <!--                    >-->
                <!--                    </a>-->
                <!--                    <a-->
                <!--                        class="fa-2xl fa-brands fa-youtube c-ezekia-footer__icon u-hover&#45;&#45;grow"-->
                <!--                        href="https://www.youtube.com/@EzekiaCRM"-->
                <!--                        target="_blank"-->
                <!--                    >-->
                <!--                    </a>-->
                <!--                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
import QmsCertificate from '@js/home-ezekia/vue-components/common/qms-certificate.vue';

const mainSiteUrl = 'https://www.ezekia.io';
const footerLinks = [
    {
        title: trans('common.privacy-policy', {}, 'en'),
        url: `${mainSiteUrl}/privacy-policy`,
    },
    {
        title: trans('common.cookie-policy', {}, 'en'),
        url: `${mainSiteUrl}/cookie-policy`,

    },
    {
        title: trans('common.subscription-agreement', {}, 'en'),
        url: `${mainSiteUrl}/subscription-agreement`,

    },
    {
        title: trans('home-ezekia.careers.nav', {}, 'en'),
        url: `${mainSiteUrl}/careers`,

    },
    {
        title: trans('home-ezekia.nav.contact', {}, 'en'),
        url: `${mainSiteUrl}/contact-8`,
    },
];

export default {
    name: 'EzekiaFooter',
    components: {
        QmsCertificate,
    },
    data() {
        return {
            footerLinks: [],
        };
    },
    computed: {
        year() {
            return (new Date).getFullYear();
        },
    },
    methods: {
        // localeLink(lang) {
        //     return `/locale/${lang}?hash=${this.$route.path}`;
        // },
    },
    created() {
        this.footerLinks = footerLinks;
    },
};
</script>

<style lang="scss" scoped>

@import "@sass/home-ezekia/variables";
@import "@sass/home-ezekia/utilities";

$padding: 30px;

.c-ezekia-footer {
    background-color: #212633;
    color: #fff;
    font-size: 13px;
    padding: $padding 0 10px 0;
    width: 100%;

    &__inner {
        padding: 0 10px;
        position: relative;
    }

    &__up {
        background-color: #444;
        position: absolute;
        right: 20px;
        top: -$padding;
    }

    &__arrow {
        color: #ddd;
        font-size: 20px;
        padding: 6px 12px;
    }

    &__main {
        //border-bottom: 1px solid #444;
        display: flex;
        margin-bottom: 10px;
        padding-bottom: 20px;
    }

    &__sides {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-right: 20px;
        min-width: 25%;
    }

    &__logo {
        background: no-repeat center center;
        background-size: contain;
        height: 60px;
        width: 185px;
    }

    &__links {
        margin-right: 40px;
        width: 22%;
    }

    &__content {
        line-height: 22px;
    }

    &__link {
        display: block;
        white-space: nowrap;
    }

    &__header {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    &__address {
        flex-grow: 1;
        max-width: 350px;
    }

    &__company {
        margin-bottom: 2px;
    }

    &__footer {
        align-items: center;
        color: #ddd;
        display: flex;
        font-size: 12px;
        justify-content: space-between;
    }

    //&__icons {
    //    color: #aaa;
    //    display: flex;
    //    font-size: 14px;
    //    gap: 10px;
    //}

    //&__icon {
    //    margin-left: 5px;
    //}

    @media (width <= 769px) {
        &__main {
            flex-wrap: wrap;
            justify-content: center;
        }

        &__left {
            margin-bottom: 10px;
        }
    }

    @media (width <= 450px) {
        &__main {
            flex-direction: column;
            padding: 0 20px 20px;
        }

        &__left {
            margin: 0 auto 14px;
        }

        &__links {
            margin: 0 0 14px;
            width: 100%;
        }

        &__footer {
            flex-direction: column;
        }

        &__copyright {
            margin-bottom: 6px;
        }

        //&__language {
        //    margin-bottom: 6px;
        //}
    }
}

a:hover {
    color: $color__cobalt-200;
}

</style>